import { useNavigation } from '@react-navigation/native'
import RoomContainer from '../components/room/RoomContainer'
import { CabanaSocketProvider } from '../providers/CabanaSocketProvider'
import { useFeatures } from '../providers/FeatureProvider'
import { RoomClientProvider } from '../providers/RoomClientProvider'
import { RoomProvider } from '../providers/RoomProvider'
import { TwilioRoomProvider } from '../providers/TwilioRoomProvider'

const RoomScreen = ({ route }) => {
  const { features } = useFeatures()
  const navigation = useNavigation()

  if (features.ZOOM_ROOMS) {
    console.log(JSON.stringify(route.params))
    navigation.navigate('ZoomMeeting', { groupId: route.params.roomId })
  }

  return (
    <CabanaSocketProvider>
      <RoomProvider
        id={route.params.roomId}
        activityId={route.params.activityId}>
        <RoomClientProvider>
          <TwilioRoomProvider>
            <RoomContainer />
          </TwilioRoomProvider>
        </RoomClientProvider>
      </RoomProvider>
    </CabanaSocketProvider>
  )
}

export default RoomScreen
