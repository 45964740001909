import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, Button, IconButton, Text, View, VStack } from 'native-base'
import { useCallback, useState } from 'react'
import { Platform, useWindowDimensions } from 'react-native'
import Modal from 'react-native-modal'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import { MemberScreenParams } from '../../screens/MemberScreen'
import HealthMinutesScreen from '../../screens/profile/HealthMinutesScreen'
import WeeklyInsightsContainer from '../insights/WeeklyInsightsContainer'
import FaIcon from '../_shared/FaIcon'
import MinutesProgressBarProfile from './MinutesProgressBarProfile'

const WeeklyProgress = () => {
  const { height } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()

  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const headerHeight = height * 0.36

  const [isModalVisible, setModalVisible] = useState(false)

  const toggleModal = () => {
    setModalVisible(!isModalVisible)
  }

  const editHealthMinutes = useCallback(() => {
    navigation.navigate('MentalHealth')
  }, [])

  return (
    <VStack>
      <Box
        height="250px"
        borderRadius="20px"
        borderColor="grey.200"
        borderWidth={1}
        alignItems="center"
        backgroundColor="white"
        p={4}
        justifyContent="space-between"
        marginX={!isDesktop && 5}
        marginTop={!isDesktop && 12}
        marginBottom={5}>
        <View
          width="100%"
          alignSelf="flex-start"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between">
          <Text fontWeight={700} color="primary.900" fontSize="16px">
            Weekly progress
          </Text>
          <IconButton
            m={-2}
            borderRadius="full"
            icon={<FaIcon name="pen" color="primary.900" size={20} />}
            onPress={() => {
              if (isDesktop) {
                toggleModal()
              } else {
                editHealthMinutes()
              }
            }}
          />
        </View>
        <MinutesProgressBarProfile
          headerHeight={
            Platform.OS === 'web' ? headerHeight + 50 : headerHeight
          }
        />
        {!isDesktop && (
          <Button
            mt={-16}
            pr={0}
            variant="link"
            alignSelf="flex-end"
            onPress={() => navigation.navigate('Insights')}>
            <Text
              textDecorationLine="underline"
              color="primary.900"
              fontSize="12px">
              Insights
            </Text>
          </Button>
        )}
      </Box>
      {isDesktop && <WeeklyInsightsContainer />}
      <Modal isVisible={isModalVisible}>
        <HealthMinutesScreen closeModal={toggleModal} />
      </Modal>
    </VStack>
  )
}

export default WeeklyProgress
