import { FlatList } from 'native-base'
import { useMemo } from 'react'
import { useDesktopBreakpoint } from '../../../../domain/services/useBreakpoint'
import ProfileItem from '../../components/profile/ProfileItem'
import ProfileContainer from '../../components/_shared/layout/ProfileContainer'
import useLinkHandler from '../../hooks/useLinkHandler'

const AboutScreen = () => {
  const isDesktop = useDesktopBreakpoint()
  const data = useMemo(
    () => [
      {
        id: 'FAQ',
        name: 'About Cabana',
        icon: 'tree-palm',
        hasRightIcon: true,
        action: () => useLinkHandler('https://www.mycabana.health/'),
        rightIcon: 'arrow-up-right-from-square',
      },
      {
        id: 'PRIVACY_POLICY',
        name: 'Privacy policy',
        icon: 'file-shield',
        hasRightIcon: true,
        action: () =>
          useLinkHandler('https://www.mycabana.health/privacy-policy'),
        rightIcon: 'arrow-up-right-from-square',
      },
      {
        id: 'TOS',
        name: 'Terms of service',
        icon: 'file-signature',
        hasRightIcon: true,
        action: () =>
          useLinkHandler('https://mycabana.health/terms-of-service'),
        rightIcon: 'arrow-up-right-from-square',
      },
    ],
    []
  )

  return (
    <ProfileContainer headerText="About">
      <FlatList
        borderColor="grey.200"
        borderWidth={1}
        showsVerticalScrollIndicator={false}
        flexGrow={isDesktop ? 0 : 1}
        borderRadius="20px"
        data={data}
        renderItem={({ item, index }) => {
          return (
            <ProfileItem
              item={item}
              isFirst={index === 0}
              isLast={index + 1 === data.length}
            />
          )
        }}
      />
    </ProfileContainer>
  )
}

export default AboutScreen
