import LottieView from 'react-native-web-lottie'

const animations = {
  loader: require('../../assets/images/voyages/intro.json'),
}

const VoyagesIntroImage = ({ source = 'loader' }) => {
  return (
    <LottieView
      autoPlay
      loop={true}
      style={{
        width: '100%',
      }}
      source={animations[source]}
    />
  )
}

export default VoyagesIntroImage