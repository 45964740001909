import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Button, Image, Text, View, VStack } from 'native-base'
import { Platform, PlatformIOSStatic } from 'react-native'
import useGetShadowProps from '../../hooks/useGetShadowProps'
import { MemberScreenParams } from '../../screens/MemberScreen'

const ReferralImage = require('../../assets/images/referral/referral.png')

const ShareCabana = () => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const shadow = useGetShadowProps(15)
  const iPad = Platform.OS === 'ios' && (Platform as PlatformIOSStatic).isPad

  return (
    <VStack
      borderRadius="20px"
      py={5}
      px={4}
      mx={5}
      mt={5}
      bg="white"
      flexDirection="column"
      borderColor="grey.200"
      borderWidth={1}>
      <Text color="primary.900" fontSize="16px" fontWeight={700}>
        Refer a coworker
      </Text>
      <View px={2}>
        <Image
          source={ReferralImage}
          my={5}
          alignSelf="center"
          alt="People"
          width={iPad ? 600 : 300}
          height={iPad ? 400 : 200}
        />
      </View>
      <Text color="primary.900" px={3} alignSelf="center" textAlign="center">
        Recommend Cabana to a coworker and earn rewards once they’ve signed up!
      </Text>
      <Button mt={6} onPress={() => navigation.navigate('Referral')}>
        <Text color="white" fontSize="16px">
          Get started
        </Text>
      </Button>
    </VStack>
  )
}

export default ShareCabana
