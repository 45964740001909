import { useEffect } from 'react'
import analytics from '../../../domain/services/analytics/AnalyticsService'
import clevertap from '../../../domain/services/clevertap/ClevertapAdapter'
import { useDesktopBreakpoint } from '../../../domain/services/useBreakpoint'
import ProfileViewDesktop from '../components/profile/ProfileViewDesktop'
import ProfileViewMobile from '../components/profile/ProfileViewMobile'
import FixedContainer from '../components/_shared/layout/FixedContainer'
import DefaultLayout from '../layouts/DefaultLayout'

const ProfileScreen = ({ route }) => {
  const isDesktop = useDesktopBreakpoint()
  const selectedScreen = route.params?.subscreen

  useEffect(() => {
    clevertap.registerForPush()
    analytics.track('Profile Reached')
  }, [])

  return (
    <DefaultLayout
      scrollView={isDesktop ? true : false}
      activeLabel="Profile"
      bg={isDesktop ? 'muted.50' : 'cabanaSand'}
      hasScrollBar={true}>
      {!isDesktop ? (
        <ProfileViewMobile />
      ) : (
        <FixedContainer size="lg">
          <ProfileViewDesktop selectedScreen={selectedScreen} />
        </FixedContainer>
      )}
    </DefaultLayout>
  )
}

export default ProfileScreen
