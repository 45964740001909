import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import * as Application from 'expo-application'
import {
  Box,
  IconButton,
  ScrollView,
  SectionList,
  StatusBar,
  Text,
  VStack,
} from 'native-base'
import { useCallback, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import useGetProfileActions from '../../hooks/useGetProfileActions'
import { useCabanaApi } from '../../providers/CabanaApiProvider'
import { useFeatures } from '../../providers/FeatureProvider'
import { MemberScreenParams } from '../../screens/MemberScreen'
import ShareCabana from '../referral/ShareCabana'
import FadeInView from '../_shared/FadeInView'
import FaIcon from '../_shared/FaIcon'
import ProfileContainer from '../_shared/layout/ProfileContainer'
import Tabs from '../_shared/layout/Tabs'
import Bookmarks from './Bookmarks'
import EmployersResources from './EmployersResources'
import LatestVibeCheck from './LatestVibeCheck'
import MyGroups from './MyGroups'
import ProfileItem from './ProfileItem'
import WeeklyProgressCircle from './WeeklyProgressCircle'

const Wave = require('../../assets/images/login/wave-profile.png')

const ProfileViewMobile = () => {
  const { ProfileActions } = useGetProfileActions()
  const { EapApi } = useCabanaApi()
  const [hasEap, setHasEap] = useState(false)
  const { features } = useFeatures()

  const [tabSelected, setTabSelected] = useState('PROFILE')

  const renderItem = useCallback(({ item, index, section }) => {
    return (
      <ProfileItem
        hasTopBorder
        item={item}
        isFirst={index === 0}
        isLast={index + 1 === section.data.length}
      />
    )
  }, [])

  const TabContainer = ({ children, hasMargintop = true }) => (
    <ScrollView
      showsVerticalScrollIndicator={false}
      flex={1}
      background="muted.50"
      paddingTop={hasMargintop ? 4 : 0}>
      <StatusBar barStyle="dark-content" />
      {children}
      <Box height={16} />
    </ScrollView>
  )

  const ProfileContent = () => (
    <TabContainer>
      <LatestVibeCheck />
      <Bookmarks />
      <MyGroups />
      <ShareCabana />
      {hasEap && <EmployersResources />}
    </TabContainer>
  )

  const AchievementsContent = () => (
    <TabContainer hasMargintop={false}>
      <Box mt={-8}>
        <WeeklyProgressCircle />
      </Box>
    </TabContainer>
  )

  const SettingsContent = () => (
    <TabContainer hasMargintop={false}>
      <Box mt={-6}>
        <SectionList
          my={-7}
          bounces={false}
          sections={ProfileActions}
          renderSectionFooter={() => <Box width="100%" height="24px" />}
          renderItem={renderItem}
          ListFooterComponent={() => (
            <Box alignSelf="center">
              <Text ml={2} mb={10}>
                App version {Application.nativeApplicationVersion}, build{' '}
                {Application.nativeBuildVersion}
              </Text>
            </Box>
          )}
        />
      </Box>
    </TabContainer>
  )

  const tabsData = {
    ['PROFILE']: {
      title: 'Profile',
      content: <ProfileContent />,
    },
    ['Achievements']: {
      title: 'Achievements',
      content: <AchievementsContent />,
    },
    ['SETTINGS']: {
      title: 'Settings',
      content: <SettingsContent />,
    },
  }

  useEffect(() => {
    ;(async () => {
      try {
        const eapPage = await EapApi.getEapPage()
        setHasEap(eapPage.data)
      } catch (e) {
        setHasEap(false)
      }
    })()
  }, [])

  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()

  return features.PROFILE_TAB ? (
    <VStack safeAreaTop bg="white" flex={1}>
      <Tabs
        hasShadow={false}
        tabSelected={tabSelected}
        selectTab={setTabSelected}
        tabsData={tabsData}
      />
    </VStack>
  ) : (
    <ProfileContainer
      headerHeight={20}
      waveImage={Wave}
      headerText="Profile"
      title="Profile"
      hasBackButton={false}
      button={
        <IconButton
          onPress={() => navigation.navigate('Account')}
          icon={<FaIcon name="gear" color="secondary.700" size={20} />}
          borderRadius="full"
        />
      }>
      <ScrollView showsVerticalScrollIndicator={false} flex={1}>
        <FadeInView>
          <WeeklyProgressCircle />
          <LatestVibeCheck />
          <MyGroups />
          <Bookmarks />
          {Platform.OS === 'ios' && <ShareCabana />}
          {hasEap && <EmployersResources />}
          <SectionList
            my={-7}
            bounces={false}
            sections={ProfileActions}
            renderSectionFooter={() => <Box width="100%" height="24px" />}
            renderItem={renderItem}
            ListFooterComponent={() => (
              <Box alignSelf="center">
                <Text ml={2} mb={10}>
                  App version {Application.nativeApplicationVersion}, build{' '}
                  {Application.nativeBuildVersion}
                </Text>
              </Box>
            )}
          />
        </FadeInView>
      </ScrollView>
    </ProfileContainer>
  )
}

export default ProfileViewMobile
