import { useNavigation } from '@react-navigation/native'
import { Platform } from 'expo-modules-core'
import { Text } from 'native-base'
import { openUrl } from '../../../../../domain/services/LinkService'
import useLinkHandler from '../../../hooks/useLinkHandler'

const TextLink = ({
  href = null,
  color = 'secondary.700',
  fontWeight = 'normal',
  hasUnderline = true,
  children,
}) => {
  const navigation = useNavigation()

  const handlePress = async () => {
    if (href?.startsWith('http')) {
      Platform.OS === 'web' ? openUrl(href, true) : await useLinkHandler(href)
    } else navigation.navigate(href)
  }

  return (
    <Text
      color={color}
      underline={hasUnderline}
      fontWeight={fontWeight}
      onPress={handlePress}>
      {children}
    </Text>
  )
}

export default TextLink
