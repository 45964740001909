import { Center, Image, StatusBar, Text, View, VStack } from 'native-base'
import { useMemo } from 'react'
import { useWindowDimensions } from 'react-native'
import { useMobileBreakpoint } from '../../../domain/services/useBreakpoint'
import Loader from '../components/_shared/Loader'
import useRandomLoaderMessage from '../hooks/useRandomLoaderMessage'

interface LoadingScreenProps {
  title?: string
  bg?: string
  hasRandomMessage?: boolean
}

const Wave = require('../assets/images/login/wave-loading.png')

const LoadingScreen = ({
  title,
  bg = 'cabanaSand',
  hasRandomMessage = true,
}: LoadingScreenProps) => {
  const randonMessage = useMemo(useRandomLoaderMessage, [])
  const { width } = useWindowDimensions()
  const isMobile = useMobileBreakpoint()

  return (
    <Center h="100%" w="100%" background={bg}>
      <StatusBar barStyle="dark-content" />
      {isMobile && (
        <Image
          source={Wave}
          alt="Wave"
          position="absolute"
          top={0}
          left="0"
          width={width}
          resizeMode="cover"
          height={'120px'}
          borderTopRadius={isMobile ? 0 : '3xl'}
        />
      )}
      <VStack paddingX={50} justifyContent="center" alignItems="center" mt={3}>
        <View width={200} height={200} mb={6}>
          <Loader />
        </View>
        {title && (
          <Text
            fontWeight={700}
            mb={4}
            textAlign="center"
            color="primary.900"
            fontSize={18}>
            {title}
          </Text>
        )}
        {hasRandomMessage && (
          <Text textAlign="center" color="primary.900">
            {randonMessage.message}
          </Text>
        )}
      </VStack>
    </Center>
  )
}

export default LoadingScreen
