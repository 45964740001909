import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { Box, FlatList, HStack, Pressable, Text, VStack } from 'native-base'
import { useWindowDimensions } from 'react-native'
import { FocusArea } from '../../../../../domain/models/interfaces/voyagesInterfaces'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import { useRenderFocusAreaIcon } from '../../../hooks/useRenderFocusAreasIcon'
import { MemberScreenParams } from '../../../screens/MemberScreen'
import FaIcon from '../../_shared/FaIcon'

const ExtraPaths = ({ focusAreas }) => {
  const navigation =
    useNavigation<NativeStackNavigationProp<MemberScreenParams>>()
  const { width } = useWindowDimensions()
  const isDesktop = useDesktopBreakpoint()
  return (
    <Box
      mb={4}
      borderRadius="20px"
      bg="white"
      p={4}
      borderColor="grey.200"
      borderWidth={1}>
      <Text color="primary.900" fontWeight={700} fontSize="17px" mb={6}>
        More paths for you
      </Text>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={focusAreas}
        keyExtractor={(item, index) => index.toString()}
        renderItem={({ item, index }: { item: FocusArea; index: number }) => {
          const { icon, color, bg } = useRenderFocusAreaIcon(item.name)
          const formattedFocusArea = item.name.replace(/\s+/g, '_')
          return (
            <Pressable
              onPress={() => {
                navigation.navigate('Path', {
                  id: item.nextPath.id,
                  focusArea: item.name,
                })
              }}
              flexDirection="row"
              alignItems="center"
              mb={index !== 2 ? 3 : 0}
              borderRadius="20px"
              bg="white"
              p={4}
              borderColor="grey.200"
              borderWidth={1}>
              <HStack
                borderRadius="10px"
                alignItems="center"
                justifyContent="center"
                width="56px"
                height="56px"
                background={bg}>
                <FaIcon name={icon} color={color} size={30} />
              </HStack>
              {item.nextPath && <VStack ml={4}>
                <Text color="muted.900" fontWeight={500}>
                  {item.nextPath.name}
                </Text>
                <Text
                  width={isDesktop ? '200px' : width - 170}
                  fontSize="10px"
                  color="text.500"
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  {`${item.nextPath.description.split('.')[0]}.`}
                </Text>
              </VStack>}
            </Pressable>
          )
        }}
      />
    </Box>
  )
}

export default ExtraPaths
