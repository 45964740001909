import { Helmet, HelmetProvider } from 'react-helmet-async'

const ReactHelmetProvider = ({ children }) => (
  <HelmetProvider>
    <Helmet>
      <meta
        http-equiv="origin-trial"
        content="AnQtBz6J8a/lI/g0+nI60Q4dpzrj+Nlpks4IXNTbxnl2fn3ZvzuLHURLZhLNNLCulBGPLKEO//22H9Naxe43PQ8AAABheyJvcmlnaW4iOiJodHRwOi8vbG9jYWxob3N0OjE5MDA2IiwiZmVhdHVyZSI6IlVucmVzdHJpY3RlZFNoYXJlZEFycmF5QnVmZmVyIiwiZXhwaXJ5IjoxNzM5OTIzMTk5fQ=="
      />
    </Helmet>
    {children}
  </HelmetProvider>
)

export default ReactHelmetProvider
