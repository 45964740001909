import {
  Box,
  Flex,
  HStack,
  Image,
  StatusBar,
  Text,
  View,
  VStack,
} from 'native-base'
import { useWindowDimensions } from 'react-native'
import { useDesktopBreakpoint } from '../../../../../domain/services/useBreakpoint'
import useGetOasisColors from '../../../hooks/useGetOasisColors'
import BackButton from '../../navigation/BackButton'

const Wave = require('../../../assets/images/profile/wave-insights.png')

const ProfileContainer = ({
  headerText,
  children,
  title,
  button,
  waveImage = Wave,
  rightAction = null,
  hasBackButton = true,
  headerHeight = 10,
}) => {
  const isDesktop = useDesktopBreakpoint()
  const { width } = useWindowDimensions()
  const { tabColor } = useGetOasisColors()

  if (!isDesktop) {
    return (
      <>
        <StatusBar barStyle="dark-content" />
        {isDesktop ? (
          <Flex bg={tabColor} h="100%" safeAreaTop w="100%">
            <Box mx="14px" mt="24px" mb="35px">
              <Header headerText={headerText} />
            </Box>
            <Box flex={1} bg="white" borderTopRadius="2xl">
              {children}
            </Box>
          </Flex>
        ) : (
          <View flex={1} bg="cabanaSand">
            <Image
              bg="transparent"
              source={waveImage}
              alt="Wave"
              position="absolute"
              top={0}
              left={0}
              width={width}
              resizeMode="cover"
              height={120}
            />
            <HStack
              bg="transparent"
              height={headerHeight}
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              position="relative"
              px={6}
              marginTop={55}>
              {hasBackButton ? (
                <BackButton />
              ) : (
                <Text
                  ml={4}
                  mb={4}
                  color="primary.900"
                  fontWeight={700}
                  fontSize="18px">
                  {title}
                </Text>
              )}
              {button}
            </HStack>
            <VStack mt={hasBackButton ? 5 : -5} flex={1}>
              {hasBackButton && (
                <HStack justifyContent="space-between">
                  <Text
                    ml={4}
                    mb={4}
                    color="primary.900"
                    fontWeight={700}
                    fontSize="18px">
                    {title}
                  </Text>
                  {rightAction}
                </HStack>
              )}
              {children}
            </VStack>
          </View>
        )}
      </>
    )
  }
  return children
}

const Header = ({ headerText }) => (
  <HStack alignItems="center">
    <HStack position="absolute" alignItems="center">
      <BackButton color="white" customText={headerText} />
    </HStack>
  </HStack>
)

export default ProfileContainer
