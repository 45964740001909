export interface Moderator {
  id: string
  name: string
  photoUrl: string
}

export interface Tag {
  id: string
  name: string
}

export enum ActivityCardBorderState {
  DEFAULT = 'DEFAULT',
  MODERATING = 'MODERATING',
  LIVE = 'LIVE',
  COMPLETED = 'COMPLETED',
  CHECKED = 'CHECKED',
  ATTENDING = 'ATTENDING',
}

export enum ActivityRegistrationState {
  AVAILABLE = 'AVAILABLE',
  ATTENDING = 'ATTENDING',
  MODERATING = 'MODERATING',
  FULL = 'FULL',
}

export enum ActivityRecommendationState {
  RECOMMENDED = 'RECOMMENDED',
  NOT_RECOMMENDED = 'NOT_RECOMMENDED',
  COMPLETED = 'COMPLETED',
}

export enum ActivityType {
  GROUP_LISTEN = 'Listen In',
  GROUP_SKILL = 'Skill Builder',
  GROUP_SERIES = 'Series',
  GROUP_SUPPORT = 'Support',
  GROUP_OTHER = 'Other',
  ARTICLE = 'Article',
  AUDIO = 'Audio',
  VIDEO = 'Video',
  ACTIVITY = 'Activity',
  MOMENT = 'Moment',
  QUIZ = 'Quiz',
  VIBE_CHECK = 'Vibe Check',
  FOR_YOU = 'For You',
  INTERACTIVE = 'Interactive',
  COMPASS = 'Compass'
}

export const GroupTypes = [
  ActivityType.GROUP_LISTEN,
  ActivityType.GROUP_SKILL,
  ActivityType.GROUP_SERIES,
  ActivityType.GROUP_SUPPORT,
  ActivityType.GROUP_OTHER,
]

export interface Activity {
  id: string
  activityId: string
  title: string
  description: string
  type: ActivityType
  cardBorderState: ActivityCardBorderState
  moderator: Moderator
  duration: number
  liked: boolean
  bookmarked: boolean
}

export interface Group extends Activity {
  id: string
  startDate: Date
  endDate: Date
}

export interface Content extends Activity {
  subtitle: string
  createdDate: Date
}

export function groupDateFormat(date: Date): string {
  try {
    if (!date) return ''

    const d = Intl.DateTimeFormat([], {
      weekday: 'short',
      month: 'short',
      day: '2-digit',
    }).format(new Date(date))

    const t = Intl.DateTimeFormat([], {
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short',
    }).format(new Date(date))
    return `${d} • ${t}`
  } catch (e) {
    console.debug(e)
    return ''
  }
}
