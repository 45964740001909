import { extendTheme } from 'native-base'

const defaultShades = {
  primary: 700,
  secondary: 500,
  tertiary: 500,
  success: 500,
  error: 500,
}

const defaultColors = {
  primary: `primary.${defaultShades.primary}`,
  secondary: `secondary.${defaultShades.secondary}`,
  tertiary: `tertiary.${defaultShades.tertiary}`,
  success: `success.${defaultShades.success}`,
  error: `error.${defaultShades.error}`,
}

export const theme = extendTheme({
  components: {
    Button: {
      variants: {
        solid: ({ colorScheme }) => {
          return {
            _light: {
              bg: `${colorScheme}.${defaultShades[colorScheme]}`,
              _hover: {
                bg: `${colorScheme}.${defaultShades[colorScheme] + 100}`,
              },
              _pressed: {
                bg: `${colorScheme}.${defaultShades[colorScheme] + 200}`,
              },
            },
          }
        },
        outline: ({ colorScheme }) => {
          return {
            _light: {
              borderWidth: '2px',
              borderColor: `${colorScheme}.${defaultShades[colorScheme]}`,
            },
          }
        },
      },
      sizes: {
        lg: {
          _text: {
            fontSize: 'lg',
          },
          _icon: {
            fontSize: 'lg',
          },
        },
        md: {
          _text: {
            fontSize: 'md',
          },
          _icon: {
            fontSize: 'md',
          },
        },
        sm: {
          _text: {
            fontSize: 'sm',
          },
          _icon: {
            fontSize: 'sm',
          },
        },
        xs: {
          _text: {
            fontSize: 'xs',
          },
          _icon: {
            fontSize: 'xs',
          },
        },
      },
    },
    Modal: {
      baseStyle: {
        _backdropFade: { exitDuration: 100, entryDuration: 100 },
        _slide: { duration: 100 },
        _fade: { exitDuration: 100, entryDuration: 100 },
      },
    },
    Text: {
      defaultProps: {
        color: defaultColors.primary,
      },
    },
    Heading: {
      defaultProps: {
        color: defaultColors.primary,
      },
    },
  },
  colors: {
    primary: {
      50: '#E8F4FD',
      100: '#D6EAFA',
      200: '#B0D0F2',
      300: '#8CB2E9',
      400: '#6A92DC',
      500: '#4A70CE',
      600: '#30489C',
      700: '#222B60',
      800: '#131B3A',
      900: '#060A19',
    },
    secondary: {
      50: '#C8FFF4',
      100: '#C3EBE7',
      200: '#03DAC5',
      300: '#00C4B4',
      400: '#00B3A6',
      500: '#00A09B',
      600: '#018786',
      700: '#017374',
      800: '#005457',
      900: '#0C3838',
    },
    tertiary: {
      50: '#FFF5F7',
      100: '#FFEBED',
      200: '#FFD1D4',
      300: '#FEB5B4',
      400: '#F79188',
      500: '#EA6852',
      600: '#CC4C1A',
      700: '#B54D14',
      800: '#8A3F0D',
      900: '#612E05',
    },
    yellow: {
      50: '#FFF5EB',
      100: '#FFECD6',
      200: '#FFDEB3',
      300: '#FFD48D',
      400: '#FFC561',
      500: '#FFBF3C',
      600: '#CA8A04',
      700: '#A16207',
      800: '#854D0E',
      900: '#713F12',
    },
    success: {
      50: '#F0FDF4',
      100: '#DCFCE7',
      200: '#BBF7D0',
      300: '#86EFAC',
      400: '#4ADE80',
      500: '#22C55E',
      600: '#16A34A',
      700: '#15803D',
      800: '#166534',
      900: '#14532D',
    },
    error: {
      50: '#FEF2F2',
      100: '#FEE2E2',
      200: '#FECACA',
      300: '#FCA5A5',
      400: '#F87171',
      500: '#Ef4444',
      600: '#DC2626',
      700: '#B91C1C',
      800: '#991B1B',
      900: '#7F1D1D',
    },
    grey: {
      200: '#0000001A',
      300: '#060a1933',
    },
    cabanaSand: '#F7F4F0',
  },
  fontConfig: {
    Poppins: {
      100: {
        normal: 'Poppins_400Regular',
        italic: 'Poppins_400Regular_Italic',
      },
      200: {
        normal: 'Poppins_400Regular',
        italic: 'Poppins_400Regular_Italic',
      },
      300: {
        normal: 'Poppins_400Regular',
        italic: 'Poppins_400Regular_Italic',
      },
      400: {
        normal: 'Poppins_400Regular',
        italic: 'Poppins_400Regular_Italic',
      },
      500: {
        normal: 'Poppins_500Medium',
        italic: 'Poppins-500Medium_Italic',
      },
      600: {
        normal: 'Poppins_600SemiBold',
        italic: 'Poppins_600SemiBold_Italic',
      },
      700: {
        normal: 'Poppins_700Bold',
        italic: 'Poppins_700Bold_Italic',
      },
      800: {
        normal: 'Poppins_700Bold',
        italic: 'Poppins_700Bold_Italic',
      },
      900: {
        normal: 'Poppins_700Bold',
        italic: 'Poppins_700Bold_Italic',
      },
    },
  },
  fonts: {
    heading: 'Poppins',
    body: 'Poppins',
  },
  shadows: {
    0: {
      shadowOffset: {
        height: 0,
      },
    },
    1: {
      shadowOffset: {
        height: 0,
      },
    },
    2: {
      shadowOffset: {
        height: 0,
      },
    },
    3: {
      shadowOffset: {
        height: 0,
      },
    },
    4: {
      shadowOffset: {
        height: 0,
      },
    },
    5: {
      shadowOffset: {
        height: 0,
      },
    },
    6: {
      shadowOffset: {
        height: 0,
      },
    },
    7: {
      shadowOffset: {
        height: 0,
      },
    },
    8: {
      shadowOffset: {
        height: 0,
      },
    },
    9: {
      shadowOffset: {
        height: 0,
      },
    },
  },
})
