import { Button, Heading, VStack } from 'native-base'
import { useEffect, useState } from 'react'
//import ZoomVideo, { InitOptions } from '@zoom/videosdk'
import { ZoomMtg } from '@zoom/meetingsdk'
import useAuthentication from '../../../hooks/authentication/useAuthentication'
import { useCabanaApi } from '../../../providers/CabanaApiProvider'
import { ZoomMeetingParams } from './ZoomMeeting'

const ZoomMeeting = ({ groupId }: ZoomMeetingParams) => {
  var authEndpoint = ''
  var sdkKey = ''
  var passWord = ''
  var role = 0
  var userName = 'React'
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''
  var leaveUrl = 'http://localhost:19006'

  const [meetingNumber, setMeetingNumber] = useState(
    crypto.randomUUID().toString().replaceAll('-', '').slice(0, 12)
  )

  const { user } = useAuthentication()
  const { ZoomApi } = useCabanaApi()

  const join = () => {
    console.log(ZoomMtg.preLoadWasm())
    console.log(ZoomMtg.prepareWebSDK())

    ZoomMtg.init({
      debug: true,
      leaveUrl: '/',
      success: async () => {
        const serverConfig = await ZoomApi.join(groupId)
        const config = {
          sdkKey: 'mykQpQO1RBuA9WkCb76eEA',
          meetingNumber: serverConfig.id,
          userName: serverConfig.alias,
          passWord: serverConfig.password,
          signature: serverConfig.token,
          success: console.log,
          error: console.error,
        }
        console.log('join', config)
        ZoomMtg.join(config)
      },
      error: console.error,
    })
  }

  /*
  const startMeeting = (host) => {
    ZoomMtg.preLoadWasm()
    ZoomMtg.prepareWebSDK()

    ZoomMtg.init({
      debug: true,
      leaveUrl: '/',
      success: async () => {
        const serverConfig = host ? await ZoomApi.host(meetingNumber) : await ZoomApi.join(meetingNumber)
        delete serverConfig.zak

        const config = {
          ... serverConfig,
          sdkKey: 'mykQpQO1RBuA9WkCb76eEA',
          meetingNumber,
          userName: user.email.split('@')[0],
          success: console.log,
          error: console.error
        }
        console.log(JSON.parse(JSON.stringify(config)))
        ZoomMtg.join(config)
      }
    })
  }
  */

  useEffect(() => {
    //join()
  }, [])

  return (
    <VStack flex={1} justifyContent={'space-around'} alignItems={'center'}>
      <Heading>Group ID: {groupId}</Heading>
      <Button onPress={join}>Let's go</Button>
    </VStack>
  )
}

export default ZoomMeeting
